/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import CustomButton from '../../common/CustomButton/CustomButton';
import CustomLink from '../../common/CustomLink/CustomLink';
import { Icon } from '../../common/Icon/Icon';
import { TBackground, addBackground } from '../../core/helpers';
import useModal from '../../core/hooks/useModal';
import { ILink } from '../../core/models';
import Chat from './Chat/Chat';
import './GPT2.css';

interface IProps {
  title: string;
  desc?: string;
  cta?: ILink;
  gpt?: {
    title?: string;
    assistantID?: string;
    btnLabel?: string;
    btnId?: string;
  };
  background?: TBackground;
}

function GPT2({ title, desc, cta, gpt, background }: IProps) {
  const { modal, open, close } = useModal({});
  const btnRef = useRef(null);

  const openModal = useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
  }, [open]);

  return (
    <section
      className={`gpt ${background ? ' section-bg' : ' section'}`}
      {...addBackground({ img: undefined, gradient: background })}
    >
      <div className="wrap wrap--lg">
        <div className="gpt__container gap-lg">
          <div className="gpt__left">
            <h2 className="gpt__title h2">
              <ReactMarkdown
                components={{
                  p: React.Fragment as any,
                }}
              >
                {title}
              </ReactMarkdown>
            </h2>
          </div>
          <div className="gpt__right gap-md">
            {!desc ? null : <ReactMarkdown className="gpt__desc h3">{desc}</ReactMarkdown>}
            {!cta && !gpt?.assistantID ? null : (
              <div className="gpt__btn-container">
                {!cta ? null : <CustomLink className="gpt__cta" link={cta} />}
                {!gpt?.assistantID ? null : (
                  <CustomButton
                    className="btn"
                    onClick={openModal}
                    type="button"
                    ref={btnRef}
                    id={gpt?.btnId}
                  >
                    {gpt.btnLabel || 'Open'}
                  </CustomButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!gpt?.assistantID
        ? null
        : modal({
            keepMounted: true,
            size: ' lg',
            children: (
              <>
                <button className="modal__close" onClick={close} type="button">
                  <Icon.Plus className="icon-large" />
                  <span className="sr-only">Close modal</span>
                </button>
                {!gpt?.assistantID ? null : (
                  <Chat assistantID={gpt.assistantID} title={gpt.title} />
                )}
              </>
            ),
          })}
    </section>
  );
}

export default GPT2;
