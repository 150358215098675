import React from 'react';
import { useNavigate } from 'react-router-dom';

import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomDropdown from '../../../common/CustomDropdown/CustomDropdown';
import { Icon } from '../../../common/Icon/Icon';
import Image from '../../../common/Image/Image';
import { useUserContext } from '../../../core/context/user.context';
import './UserDropdown.css';

function UserDropdown() {
  const { user, logout } = useUserContext();
  const navigate = useNavigate();

  return (
    <CustomDropdown
      selected=""
      items={[
        { value: 'account', label: 'Subscriptions' },
        { value: 'logout', label: 'Log out' },
      ]}
      handleChange={(e: React.MouseEvent<HTMLLIElement, MouseEvent>, val: string) => {
        switch (val) {
          case 'account':
            navigate('/user');
            break;
          case 'logout':
            logout();
            navigate('/');
            break;
          default:
            break;
        }
      }}
    >
      <CustomButton type="button" className="user-dropdown__avatar">
        {user.profile_pic ? (
          <Image className="user__img" img={user.profile_pic} size="small" />
        ) : (
          <Icon.User />
        )}
      </CustomButton>
    </CustomDropdown>
  );
}

export default UserDropdown;
