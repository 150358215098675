/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react/no-array-index-key */
import Audience from '../Audience/Audience';
import Commitment from '../Commitment/Commitment';
import FAQ from '../FAQ/FAQ';
import GPT2 from '../GPT2/GPT2';
import ImageSection from '../ImageSection/ImageSection';
import Introduction from '../Introduction/Introduction';
import Mission from '../Mission/Mission';
import Product from '../Product/Product';
import Result from '../Result/Result';
import Support from '../Support/Support';
import Testimonials from '../Testimonials/Testimonials';
import Title from '../Title/Title';

interface IProps {
  data: any;
  childrendRef?: any;
  slug: string;
}

function PageBuilder({ data, slug }: IProps) {
  return data.map((component: any, i: number) => {
    if (component.hidden) return null;
    switch (component.component) {
      case 'commitment':
        return <Commitment key={i} {...component} />;
      case 'mission':
        return <Mission slug={slug} key={i} {...component} />;
      case 'product':
        return <Product key={i} {...component} />;
      case 'audience':
        return <Audience slug={slug} key={i} {...component} />;
      case 'testimonials':
        return <Testimonials key={i} {...component} />;
      case 'faq':
        return <FAQ key={i} {...component} />;
      case 'title':
        return <Title key={i} {...component} />;
      case 'result':
        return <Result key={i} {...component} slug={slug} />;
      case 'gpt2':
        return <GPT2 key={i} {...component} />;
      case 'introduction':
        return <Introduction key={i} {...component} />;
      case 'image':
        return <ImageSection key={i} {...component} />;
      case 'support':
        return <Support key={i} {...component} />;
      default:
        return null;
    }
  });
}

export default PageBuilder;
